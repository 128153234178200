import { Injectable } from '@angular/core';

import { GetAllDepartmentGQL, GetDepartmentGQL } from '@alan-apps/data-access';
import {
  BaseHttpService,
  cache,
  ICache,
  ONE_YEAR,
} from '@nghedgehog/angular-ui';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService implements ICache {
  readonly storageKey = 'DepartmentService';

  constructor(
    private _http: BaseHttpService,
    private getDepartmentGQL: GetDepartmentGQL,
    private getAllDepartmentGQL: GetAllDepartmentGQL,
  ) {}

  get(id: string) {
    return this._http.apollo(this.getDepartmentGQL.fetch({ id }));
  }

  @cache({
    timeMs: ONE_YEAR,
  })
  all({
    info: withInfo = false,
    root = true,
  }: { info?: boolean; root?: boolean } = {}) {
    return this._http.apollo(
      this.getAllDepartmentGQL.fetch({ root, withInfo }),
    );
  }
}
